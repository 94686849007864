/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.App {
  min-height: 100vh !important;
  max-height: 100vh !important;
  box-sizing: border-box;
}

body {
  height: 100vh;
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Typography custom */

h1 {
  /* H1 - 38 */
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: rgba(0, 0, 0, 0.85);
}

h2 {
  /* H2 - 30 */
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.85);
}

h3 {
  /* H3 - 24 */
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

h4 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

p, .ant-table-tbody>tr>td, .ant-table-thead>tr>th, a, b {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0 !important;
}

.ant-form label {
  font-size: 16px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

::-webkit-scrollbar-track {
  background: transparent; 
}
::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 0;
}
a, a.ant-typography {
  color: #3E73E3;
}

.ant-layout-content {
  overflow-y: auto;
}

li.ant-pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-confirm-content {
  white-space: pre-line;
}